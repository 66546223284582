import { useState } from 'react'
import { useRemixFormContext } from 'remix-hook-form'
import cx from 'classnames'

import useMediaQuery from '~/hooks/useMediaQuery'
import {
  pricing,
  LATEST_INDIVIDUAL_MONTHLY_PLAN,
  LATEST_INDIVIDUAL_ANNUAL_PLAN,
  LATEST_SCHOOL_ANNUAL_PLAN
} from '~/config/pricing'
import Button from '~/components/Button'
import Switch from '~/components/forms/Switch'
import SelectInput from '~/components/forms/SelectInput'
import PlansList from './PlansList'
import PlanCard from './PlanCard'
import { SubscriptionType } from '~/types/subscriptions'
import PriceInfo from './PriceInfo'

export const getBullets = (includeCommon: boolean, subscriptionType?: SubscriptionType) =>
  [
    ...(includeCommon ? ['400+ educational videos', 'Printable resources', 'Monthly newsletter'] : []),
    subscriptionType ? (subscriptionType === 'individual' ? ['7 day free trial'] : ['Payment by invoice']) : ''
  ].filter(Boolean) as string[]

export const seatsOptions = () => {
  const options = []
  for (let i = 2; i <= 20; i++) {
    options.push({ label: i.toString(), value: i.toString() })
  }
  return options
}

const INDIVIDUAL_MONTHLY_PRICE = pricing[LATEST_INDIVIDUAL_MONTHLY_PLAN]
const INDIVIDUAL_ANNUAL_PRICE = pricing[LATEST_INDIVIDUAL_ANNUAL_PLAN]
const SCHOOL_ANNUAL_PRICE = pricing[LATEST_SCHOOL_ANNUAL_PLAN]

interface SignUpPlanPanelProps {
  className?: string
  isMobileView?: boolean
  onComplete: () => void
}

const SignUpPlanPanel = ({ className, isMobileView = false, onComplete }: SignUpPlanPanelProps) => {
  const isMobile = useMediaQuery('(max-width: 640px)') || isMobileView

  // Truthy will follow the value of second option,
  // false -> left option, true -> right option
  const [isAnnual, setIsAnnual] = useState<boolean>(true)

  const handlePeriodSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isAnnual = event.target.checked
    setIsAnnual(isAnnual)
    configureIndividualPlan(isAnnual)
  }

  const { watch, setValue } = useRemixFormContext()
  const plan = watch('plan')

  const isSchool = plan.startsWith(SubscriptionType.SCHOOL)
  const isIndividual = plan.startsWith(SubscriptionType.INDIVIDUAL)

  const configureIndividualPlan = (isAnnual: boolean) => {
    setValue('plan', isAnnual ? LATEST_INDIVIDUAL_ANNUAL_PLAN : LATEST_INDIVIDUAL_MONTHLY_PLAN)
    setValue('subscriptionUserSeats', 1)
  }

  const configureSchoolPlan = () => {
    setValue('plan', LATEST_SCHOOL_ANNUAL_PLAN)
    setValue('subscriptionUserSeats', 2)
  }

  return (
    <div className={cx('w-full', className)}>
      <Switch
        name="frequency"
        className={cx(isMobile ? 'w-full' : 'w-[44%]', 'mb-3 flex justify-center')}
        onChange={handlePeriodSwitch}
        checked={isAnnual}
        labelClassName="text-[length:inherit]"
        firstChoiceLabel="Monthly"
        secondChoiceLabel="Annual"
      />

      <PlansList
        name="subscriptionType"
        className={cx(isMobile ? 'w-inherit grid-cols-none gap-6' : 'grid-cols-2 gap-[58px]')}
        commonBullets={getBullets(isMobile)}>
        <PlanCard
          onPlanSelect={() => configureIndividualPlan(isAnnual)}
          className={cx(isMobile ? 'max-w-unset' : 'max-w-[300px]')}
          selected={isIndividual}
          tagLine={
            <div className="text-center">
              <h2 className="mb-2">Individual</h2>
              <p className={cx(isMobile ? 'mb-6 leading-5' : 'h-[55px] leading-6', 'text-base font-medium')}>
                Join as an individual user
              </p>
            </div>
          }
          header={
            <PriceInfo
              price={isAnnual ? INDIVIDUAL_ANNUAL_PRICE : INDIVIDUAL_MONTHLY_PRICE}
              plan={isAnnual ? LATEST_INDIVIDUAL_ANNUAL_PLAN : LATEST_INDIVIDUAL_MONTHLY_PLAN}
              type={SubscriptionType.INDIVIDUAL}
              isMobile={isMobile}
            />
          }
          classNameBullets={cx('ml-6', isMobile ? 'text-xs leading-4 font-medium' : '')}
          bullets={getBullets(!isMobile, SubscriptionType.INDIVIDUAL)}
          footer={
            <Button
              className={cx(isIndividual ? 'mt-8 flex-1' : isMobile ? 'mt-8 flex-1' : 'mt-auto')}
              intent={isIndividual ? 'success' : 'outlined'}
              text={isIndividual ? 'Join' : 'Select'}
              onClick={() => {
                if (isIndividual) {
                  onComplete?.()
                } else {
                  configureIndividualPlan(isAnnual)
                }
              }}
            />
          }
          sticker={
            isAnnual && (
              <img className="absolute right-0 h-16 w-16 rotate-30" src="/2-months-free.svg" alt="2-months-free" />
            )
          }
        />

        <PlanCard
          onPlanSelect={configureSchoolPlan}
          className={cx(isMobile ? 'max-w-unset h-[316px]' : 'max-w-[300px]')}
          selected={isSchool}
          tagLine={
            <div className="text-center">
              <h2 className="mb-2">School</h2>
              <p className={cx(isMobile ? 'mb-6 leading-5' : 'h-[55px] leading-6', 'text-base font-medium')}>
                Join as a school with multiple users and admins
              </p>
            </div>
          }
          header={
            <PriceInfo
              price={SCHOOL_ANNUAL_PRICE}
              type={SubscriptionType.SCHOOL}
              plan={LATEST_SCHOOL_ANNUAL_PLAN}
              isMobile={isMobile}
            />
          }
          classNameBullets={cx('ml-6', isMobile ? 'text-xs leading-4 font-medium' : '')}
          bullets={getBullets(!isMobile, SubscriptionType.SCHOOL)}
          content={
            <div className="min-h-[63.5]">
              {isSchool && (
                <div className="mb-2">
                  <SelectInput
                    className="mt-4 flex w-full flex-row items-center justify-center gap-3"
                    classNameDropdown="max-w-[100px]"
                    name="subscriptionUserSeats"
                    label="Teacher Seats"
                    options={seatsOptions()}
                  />
                </div>
              )}
            </div>
          }
          footer={
            <Button
              className={cx(isSchool ? 'mt-3' : 'mt-8 flex-1')}
              intent={isSchool ? 'success' : 'outlined'}
              text={isSchool ? 'Join' : 'Select'}
              onClick={() => {
                if (isSchool) {
                  onComplete?.()
                } else {
                  configureSchoolPlan()
                }
              }}
            />
          }
        />
      </PlansList>
    </div>
  )
}

export default SignUpPlanPanel
